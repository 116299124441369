import React from "react";
import { useTable, useGlobalFilter, useFilters, useSortBy } from "react-table";
import {
  StyledSortBtnDownIcon,
  StyledSortBtnUpIcon,
} from "assets/images/icon/Svg";
import { Cell, Table, TBody, TBodyRow, THead, THeadRow } from "./Styled";

const columnWidths = {
  id: 100,
  name: 200,
  age: 80,
  address: 300,
};

export const BasicTable = ({ columns, data, type, ...prev }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useFilters,
      useGlobalFilter,
      useSortBy
    );
  return (
    <Table {...getTableProps()}>
      <THead>
        {headerGroups.map((headerGroup) => (
          <THeadRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              return (
                <Cell
                  {...column.getHeaderProps()}
                  style={{ width: columnWidths[column.accessor] }}
                  type={type}
                >
                  {column.render("Header")}
                  {column.isSorted && (
                    <span>
                      {column.isSortedDesc ? (
                        <StyledSortBtnDownIcon />
                      ) : (
                        <StyledSortBtnUpIcon />
                      )}
                    </span>
                  )}
                </Cell>
              );
            })}
          </THeadRow>
        ))}
      </THead>
      <TBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TBodyRow {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <Cell {...cell.getCellProps()} type={type}>
                    {cell.render("Cell", { ...prev })}
                  </Cell>
                );
              })}
            </TBodyRow>
          );
        })}
      </TBody>
    </Table>
  );
};
