import styled, { css } from "styled-components";
export const Table = styled.div`
  display: table;
  width: 100%;
  margin-top: 10px;
  text-align: center;
  overflow-x: hidden;
  padding-left: 24px;
  padding-right: 24px;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;
export const THeadRow = styled(Row)`
  display: flex;
`;

export const THead = styled.div`
  font-size: 1em;
  font-weight: 700;
  background-color: transparent;
  color: #5a6387;
  height: 20px;
  display: flex;
  padding: 10px;
`;

export const TBodyRow = styled(Row)`
  border: 5px solid #f5f6fa;
  border-radius: 8px;
  height: 50px;
  background-color: white;
  font-size: ${(props) => (props.size === "small" ? "11px" : "12px")};
  padding: 10px;
`;

export const TBody = styled.div`
  color: #676d83;
  flex-direction: column;
`;

export const Cell = styled.div`
  display: table-cell;
  text-align: center;
  width: ${(props) => (props.type === "exam" ? "15%" : "100%")};

  ${(props) =>
    props.type === "exam" &&
    css`
      :first-child {
        width: 5%;
      }
      :not(:first-child):not(:last-child) {
        width: 15%;
      }
      :last-child {
        flex: 1;
      }
    `}
  audio {
    width: 100%;
    height: 30px;
    max-width: 300px;
    min-width: 80px;
    min-height: 20px;
  }
`;
