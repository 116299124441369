import { t } from "i18next";
import React from "react";
import styled from "styled-components";

const Button = styled.button`
  border-radius: 32px;
  border-color: transparent;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d60983;
  margin: 16px;
  @media (min-width: 880px) {
    margin-top: 1rem;
    display: ${(props) => (props.isManagerForm ? "none" : "block")};
    width: 100px;
  }
  @media (max-width: 880px) {
    font-size: 10px;
    width: 60px;
  }
`;

const handleLogOut = () => {
  sessionStorage.clear();
  window.location.reload();
};

const LogoutButton = ({ isManagerForm }) => {
  return (
    <Button isManagerForm={isManagerForm} onClick={handleLogOut}>
      {t("LogOut")}
    </Button>
  );
};

export default LogoutButton;
