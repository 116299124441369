import styled from "styled-components";

export const StyledButton = styled.div`
  background-color: ${(props) => props.color};
  color: ${(props) => props.fontColor};
  text-align: center;
  font-size: 1.25rem;
  font-weight: 500;

  cursor: pointer;
  user-select: none;

  font-size: 24px;

  border-radius: 50px;
  width: 204px;
  height: 68px;
  line-height: 68px;
  text-align: center;
  margin-left: 36px;
  margin-right: 36px;
`;
