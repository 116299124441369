import styled, { css } from "styled-components";

import { ReactComponent as Global } from "./global.svg";
import { ReactComponent as Points } from "./Points.svg";
import { ReactComponent as Record } from "./Record.svg";
import { ReactComponent as Save } from "./Save.svg";
import { ReactComponent as SaveFile } from "./SaveFile.svg";
import { ReactComponent as SortBtnDown } from "./SortBtnDown.svg";
import { ReactComponent as SortBtnUp } from "./SortBtnUp.svg";
import { ReactComponent as TestEval } from "./TestEval.svg";
import { ReactComponent as Update } from "./Update.svg";
import { ReactComponent as Back } from "./Back.svg";
import { ReactComponent as Close } from "./Close.svg";
import { ReactComponent as Tooltip } from "./Tooltip.svg";
import { ReactComponent as Excel } from "./Excel.svg";
import { ReactComponent as Upload } from "./Upload.svg";
import { ReactComponent as DownLoad } from "./DownLoad.svg";
import { ReactComponent as Filter } from "./Filter.svg";
import { ReactComponent as Admin } from "./Admin.svg";
import { ReactComponent as Manual } from "./Manual.svg";
import { ReactComponent as UpDown } from "./UpDown.svg";

const svgStyles = ({ height, width, color }) => {
  return css`
    height: ${height || "1.5rem"};
    width: ${width || "1.5rem"};
    & path {
      fill: ${color || "black"};
    }
    @media (max-width: 768px) {
      height: 1rem;
      width: 1rem;
    }
  `;
};

/*Template
export const Styled[SvgName]Icon = styled([SvgName])`
${props => svgStyles(props)}
`;
*/

export const StyledGlobalIcon = styled(Global)`
  ${(props) => svgStyles(props)}
`;

export const StyledPointsIcon = styled(Points)`
  ${(props) => svgStyles(props)}
`;

export const StyledRecordIcon = styled(Record)`
  ${(props) => svgStyles(props)}
`;

export const StyledUpdateIcon = styled(Update)`
  ${(props) => svgStyles(props)}
`;

export const StyledSaveIcon = styled(Save)`
  ${(props) => svgStyles(props)}
`;

export const StyledSaveFileIcon = styled(SaveFile)`
  ${(props) => svgStyles(props)}
`;

export const StyledSortBtnDownIcon = styled(SortBtnDown)`
  ${(props) => svgStyles(props)}
`;

export const StyledSortBtnUpIcon = styled(SortBtnUp)`
  ${(props) => svgStyles(props)}
`;

export const StyledTestEvalIcon = styled(TestEval)`
  ${(props) => svgStyles(props)}
`;

export const StyledBackIcon = styled(Back)`
  ${(props) => svgStyles(props)}
`;

export const StyledCloseIcon = styled(Close)`
  ${(props) => svgStyles(props)}
`;

export const StyledTooltipIcon = styled(Tooltip)`
  ${(props) => svgStyles(props)}
`;

export const StyledExcelIcon = styled(Excel)`
  ${(props) => svgStyles(props)}
`;

export const StyledDownLoadIcon = styled(DownLoad)`
  ${(props) => svgStyles(props)}
`;

export const StyledUploadIcon = styled(Upload)`
  ${(props) => svgStyles(props)}
`;

export const StyledFilterIcon = styled(Filter)`
  ${(props) => svgStyles(props)}
`;

export const StyledAdminIcon = styled(Admin)`
  ${(props) => svgStyles(props)}
`;

export const StyledManualIcon = styled(Manual)`
  ${(props) => svgStyles(props)}
`;

export const StyledUpDownIcon = styled(UpDown)`
  ${(props) => svgStyles(props)}
`;
