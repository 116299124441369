import { Table, TableCell, TableHead, TableRow } from "@material-ui/core";
import { t } from "i18next";
import React from "react";
import styled from "styled-components";

const StyledTableCell = styled(TableCell)`
  && {
    color: #718093;
    font-size: 14px;
    @media (max-width: 1250px) {
      font-size: 12px;
    }
    @media (max-width: 880px) {
      font-size: 10px;
    }
  }
`;

const ErrorCell = ({ option }) => {
  const ERROR_MESSAGE_MAPPING = {
    default: t("ErrorMessage.NoItemsSelected"),
    selctedItemNoExist: t("ErrorMessage.NoInformationItem"),
  };
  return (
    <Table>
      <TableHead>
        <TableRow>
          <StyledTableCell>{ERROR_MESSAGE_MAPPING[option]}</StyledTableCell>
        </TableRow>
      </TableHead>
    </Table>
  );
};

export default ErrorCell;
