export const style = {
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  justifyContent: "space-between",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "600px",
  minHeight: "400px",
  maxHeight: "800px", // Set the maximum height to 800px
  backgroundColor: "#fff",
  borderRadius: "4px",
  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
  overflow: "hidden",
};

export const titleStyle = {
  textTransform: "uppercase",
  margin: "0",
  padding: "16px",
  borderBottom: "1px solid #ccc",
};

export const descriptionStyle = {
  overflowY: "auto",
  flexGrow: "1",
  padding: "16px",
};

export const buttonContainerStyle = {
  display: "flex",
  justifyContent: "flex-end",
  padding: "16px",
  borderTop: "1px solid #ccc",
};

export const buttonStyle = {
  marginLeft: "16px",
};
