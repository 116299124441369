import React from "react";
import styled from "styled-components";

const Input = styled.input`
  border: 0;
`;

const InputCell = ({
  initValue,
  placeholder,
  name,
  onChange,
  inputValidation,
}) => {
  return (
    <>
      <Input
        type={"text"}
        placeholder={placeholder || initValue}
        name={name}
        onInput={inputValidation}
        onBlur={onChange}
      />
    </>
  );
};

export default InputCell;
