import { isFilteringAtom, setFiltersDataAtom } from "services/atom";
import React, { useEffect } from "react";
import { useCallback } from "react";
import { useTable, useGlobalFilter, useFilters, useSortBy } from "react-table";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  StyledSortBtnDownIcon,
  StyledSortBtnUpIcon,
} from "assets/images/icon/Svg";
import { Cell, Table, TBody, TBodyRow, THead, THeadRow } from "./Styled";

export const AdminTable = ({
  columns,
  data,
  type,
  isSort = false,
  setGrade,
  setStatus,
  setClass,
  globalFilter,
}) => {
  const setFilterData = useSetRecoilState(setFiltersDataAtom);
  const [isfiltering, setFiltering] = useRecoilState(isFilteringAtom);

  const instance = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
    setGlobalFilter,
  } = instance;

  const filterCallback = useCallback(() => {
    setFilter("grade", setGrade);
    setFilter("academicStatus", setStatus);
    setFilter("class", setClass);
    setGlobalFilter(globalFilter);
    setFiltering(true);
  }, [
    setFilter,
    setFiltering,
    setGrade,
    setClass,
    setStatus,
    globalFilter,
    setGlobalFilter,
  ]);

  useEffect(() => {
    filterCallback();
  }, [filterCallback]);

  useEffect(() => {
    const filterData = rows.map((row) => row.original);
    setFilterData(filterData);
  }, [isfiltering, rows, setFilterData]);

  return (
    <Table {...getTableProps()}>
      <THead>
        {headerGroups.map((headerGroup) => (
          <THeadRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              if (column.id === "name" || "studentid") {
                <Cell
                  {...column.getHeaderProps(
                    isSort && column.getSortByToggleProps()
                  )}
                  type={type}
                >
                  {column.render("Header")}
                </Cell>;
              }
              return (
                <Cell
                  {...column.getHeaderProps(
                    isSort && column.getSortByToggleProps()
                  )}
                  type={type}
                >
                  {column.render("Header")}
                  {column.isSorted && (
                    <span>
                      {column.isSortedDesc ? (
                        <StyledSortBtnDownIcon color={"#5a6387"} />
                      ) : (
                        <StyledSortBtnUpIcon color={"#5a6387"} />
                      )}
                    </span>
                  )}
                </Cell>
              );
            })}
          </THeadRow>
        ))}
      </THead>
      <TBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TBodyRow {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <Cell {...cell.getCellProps()}>{cell.render("Cell")}</Cell>
                );
              })}
            </TBodyRow>
          );
        })}
      </TBody>
    </Table>
  );
};
