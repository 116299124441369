import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 8px;
  height: 50px;
  @media (max-width: 880px) {
    align-items: center;
  }
`;

const Header = ({ children }) => {
  return <Container>{children}</Container>;
};

export default Header;
