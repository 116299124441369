import { Box, Button, Modal } from "@material-ui/core";
import { BasicTable } from "components/Common/Table/BasicTable";
import { ADMIN_COLUMNS } from "components/Common/Table/columns";
import { postStudents } from "services/api";
import React, { useMemo, useState } from "react";
import { t } from "i18next";
import {
  USER_TABLE_TITLE_MAPPING,
  USER_TABLE_TITLE_MAPPING_KR,
} from "services/mapping";
import { renameKeys } from "services/renameKeys";
import {
  buttonContainerStyle,
  buttonStyle,
  descriptionStyle,
  style,
  titleStyle,
} from "./Styled";
import { useMutation } from "react-query";

const Preview = ({ setSelected, onClose, data }) => {
  const [open, setOpen] = useState(false);
  const [initData, setData] = useState([]);
  const [convert, setConvert] = useState([]);
  const lng = JSON.parse(window.localStorage.getItem("lng"));
  const columns = useMemo(() => ADMIN_COLUMNS, []);
  React.useEffect(() => {
    setData(data);
  }, [data]);

  const handleModal = () => {
    const mappingType =
      lng.code === "ko"
        ? USER_TABLE_TITLE_MAPPING_KR
        : USER_TABLE_TITLE_MAPPING["en"];
    const convertJson = renameKeys(mappingType, data);
    setConvert(convertJson);
    setOpen(true);
  };
  const handleClose = () => {
    setSelected([]);
    setData([]);
    document.getElementById("upload").value = null;
    setOpen(false);
  };

  const { mutate } = useMutation(() => postStudents(convert), {
    onSuccess: () => {
      setOpen(false);
      onClose();
      alert(t("Saved"));
      window.location.reload();
    },
  });
  const handleSend = () => {
    if (window.confirm(t("SaveChanges"))) {
      mutate();
    }
  };
  return (
    <>
      <Button
        style={{
          display: initData.length > 0 ? "block" : "none",
        }}
        onClick={handleModal}
      >
        {t("Preview")}
      </Button>
      <Modal
        hideBackdrop
        open={open}
        onClose={handleModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ ...style }}>
          <h4 id="modal-title" style={{ ...titleStyle }}>
            {t("Preview")}
          </h4>
          <div id="modal-description" style={{ ...descriptionStyle }}>
            <BasicTable columns={columns} data={convert} size={"small"} />
          </div>
          <div style={{ ...buttonContainerStyle }}>
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{ ...buttonStyle }}
            >
              {t("Cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSend}
              style={{ ...buttonStyle }}
            >
              {t("Save")}
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Preview;
