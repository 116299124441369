import styled from "styled-components";

const color = {
  grey: "#B9C0DD98",
  lightGrey: "#F4F4F4",
  deepBlue: "#081D47",
  white: "#FFFFFF",
  pink: "#f70a98",
};

export const Item = styled.li`
  display: flex;
  list-style: none;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  height: 80%;
  width: 100%;
  span {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }
  &:hover {
    background-color: ${(props) => props.isHover && color.pink};
    color: ${(props) => props.isHover && color.white};
    fill: ${(props) => props.isHover && color.white};
    cursor: ${(props) => props.isHover && "pointer"};
    border-radius: ${(props) => props.isHover && "4px"};
    border: ${(props) => props.isHover && "1px solid #b9c0dd98"};
  }
  &:hover svg path {
    background-color: ${(props) => props.isHover && color.pink};
    color: ${(props) => props.isHover && color.white};
    fill: ${(props) => props.isHover && color.white};
  }
`;

export const ItemList = styled.ul`
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  width: 300px;
  height: 60px;
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: ${color.white};
  border: 1px solid ${color.grey};
  box-shadow: 1px 1px 2px ${color.grey};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 4px;
  gap: 4px;
  right: 0;
  top: 0;
  transform: translateY(-70px);
  z-index: 999;
`;

export const ItemWrapper = styled.div`
  border-radius: 5px;
  flex-direction: column;
  position: relative;
`;
