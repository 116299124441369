import { useState } from "react";
import { Modal } from "@material-ui/core";
import * as XLSX from "xlsx";
import { StyledUploadIcon } from "assets/images/icon/Svg";
import Preview from "components/Manager/Preview";
import { ImportExcelWrapper, ModalWrapper, StyledLabel } from "./Styled";

const ImportExcel = () => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //학생 데이터를 서버에 저장
  const readUploadFile = async (e) => {
    const { files } = e.target;
    setSelected([]);

    if (!files) return;

    try {
      const file = files[0];
      const buffer = await file.arrayBuffer();
      const workbook = XLSX.read(buffer, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);
      setSelected(json);
    } catch (error) {
      console.error(error);
    }
  };

  const acceptFile =
    ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";

  return (
    <>
      <ImportExcelWrapper onClick={handleOpen}>
        <StyledUploadIcon />
        <span>Upload</span>
      </ImportExcelWrapper>

      <Modal open={open} onClose={handleClose}>
        <ModalWrapper>
          <form>
            <StyledLabel htmlFor="upload">Upload Excel</StyledLabel>
            <input
              type="file"
              name="upload"
              id="upload"
              accept={acceptFile}
              onChange={readUploadFile}
            />
          </form>

          <Preview
            setSelected={setSelected}
            data={selected}
            onClose={handleClose}
          />
        </ModalWrapper>
      </Modal>
    </>
  );
};

export default ImportExcel;
