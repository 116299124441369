import styled from "styled-components";

export const Navigation = styled.nav`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin: 16px;
  @media (max-width: 880px) {
    position: relative;
    top: 50px;
  }
  z-index: 1;
`;

export const ListContainer = styled.div`
  position: absolute;
  top: 50px;
  @media (max-width: 880px) {
    top: 30px;
  }
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
`;

export const List = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 10rem;
  margin: 0;
  padding: 0.25rem;
  z-index: 1;
  background-color: #fafafa;
  color: #d60984;
`;

export const ListItem = styled.li`
  display: block;
  position: relative;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 5px;
  background-color: ${(props) => props.name === props.active && "#D60984"};
  color: ${(props) => props.name === props.active && "white"};
  &:hover {
    background-color: #f70a98;
    color: white;
  }
  span {
    display: flex;
    margin-right: 5px;
  }
`;
