import React from "react";
import { t } from "i18next";
import {
  SearchContainer,
  SearchIcon,
  SearchInput,
  StyledSelectBox,
} from "./Styled";

export const GlobalFilter = ({ setFilter }) => {
  return (
    <SearchContainer>
      <SearchInput
        type={"text"}
        placeholder={t("SearchData")}
        onChange={(e) => setFilter(e.target.value)}
      />
      <SearchIcon />
    </SearchContainer>
  );
};

export const SelectColumnFilter = ({
  filterValue,
  setFilter,
  options,
  title,
  name,
}) => {
  return (
    <StyledSelectBox>
      <label htmlFor={title}>{title}</label>
      <select
        id={title}
        defaultValue={filterValue}
        onChange={setFilter}
        label={title}
        name={name}
      >
        <option value="">{title}</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </StyledSelectBox>
  );
};
