import React, { useState } from "react";
import TitleHeader from "components/Common/Header/TitleHeader";
import GlobalHeader from "components/Common/Header/GlobalHeader";
import { GlobalStyle, Page, PageHeader, PageContants } from "./Styled";
import Navbar from "components/Common/NavBar";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ManagerForm = ({ children, titleText, itemText, titleHeader }) => {
  const [navWidth, setWidth] = useState(0);

  return (
    <>
      <GlobalStyle />
      <Container>
        <Navbar setWidth={setWidth} />
        <GlobalHeader />
        <Page navWidth={navWidth}>
          <TitleHeader titleText={titleText} itemText={itemText} />
          <PageHeader>{titleHeader}</PageHeader>
          <PageContants>{children}</PageContants>
        </Page>
      </Container>
    </>
  );
};

export default ManagerForm;
