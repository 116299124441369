const { default: styled, createGlobalStyle } = require("styled-components");

export const GlobalStyle = createGlobalStyle`
body{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #07193d;
    height: 100vh;
}
`;
export const AuthContainer = styled.div`
  display: flex;
  height: 664px;
  width: 1336px;
  margin: 4em auto;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  border-radius: 18px;
`;

export const BannerBox = styled.div`
  vertical-align: middle;
  max-width: 550px;
  img {
    height: 100%;
    width: 100%;
    vertical-align: middle;
    border-radius: 18px 0 0 18px;
  }
`;

export const AuthBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 70px;
  width: 100%;
  max-width: 880px;
  height: 100%;
  background-color: white;
  border-radius: 0 18px 18px 0;
`;

export const HeaderBlock = styled.div`
  width: 100%;
  text-align: center;
  span {
    font-size: 32px;
    font-weight: bold;
    display: inline-block;
    margin: 2vh auto;
    text-transform: uppercase;
  }
`;
export const FormBlock = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

export const FooterBlock = styled.div`
  display: flex;
  justify-content: center;
`;

export const ErrorBlock = styled.div`
  text-align: center;
  color: red;
`;
