import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 8px;
  margin: 8px;
`;

export const Title = styled.h5`
  color: #3f51b5;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.334;
  margin-bottom: 0.5rem;
`;

export const Item = styled(Container)`
  color: black;
  font-weight: lighter;
  font-weight: 700;
  flex-grow: 1;
`;
