import { useEffect } from "react";
/**
 * 클릭 이벤트가 ref 요소의 외부에서 발생하면 callback 함수를 호출
 *
 * @param {*} ref - 클릭 이벤트를 등록할 요소를 참조하는 useRef()로 생성한 객체
 * @param {*} callback - 클릭 이벤트가 발생했을 때 실행되는 콜백 함수
 */
export const useClickOutside = (ref, callback) => {
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, callback]);
};
