import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
  btn: {
    backgroundColor: (props) => (props ? "#07193d" : "white"),
    color: (props) => (props ? "white" : "#07193d"),
    borderRadius: 20,
  },
  textField: {
    display: "block",
    position: "absolute",
    width: "90%",
    top: 50,
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
    fontSize: "2rem",
  },
});

export const style = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  // flex-direction: column
  position: "absolute",
  borderRadius: 24,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75rem",
  height: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  fontSize: "2rem",
};

export const close = {
  display: "flex",
  position: "absolute",
  bottom: "5%",
  right: "5%",
};
