import React from "react";
import { useTable } from "react-table";
import { Cell, Table, TBody, TBodyRow, THead, THeadRow } from "./Styled";

export const EditTable = ({ columns, data, onChange, onSave }) => {
  const [column, setColumn] = React.useState(-1);
  const [item, setItem] = React.useState(null);
  const instance = useTable({
    columns,
    data,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  const onRowClick = (cell, index) => {
    setItem("");
    setColumn(index);
    setItem(cell.row.original);
  };

  return (
    <Table {...getTableProps()}>
      <THead>
        {headerGroups.map((headerGroup) => {
          return (
            <THeadRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  <Cell {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </Cell>
                );
              })}
            </THeadRow>
          );
        })}
      </THead>
      <TBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TBodyRow {...row.getRowProps()}>
              {row.cells.map((cell, index) => {
                return (
                  <Cell
                    onClick={() => onRowClick(cell, index)}
                    style={{
                      padding: "10px",
                    }}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell", { item, column, onChange, onSave })}
                  </Cell>
                );
              })}
            </TBodyRow>
          );
        })}
      </TBody>
    </Table>
  );
};
