import { t } from "i18next";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  const access = sessionStorage.getItem("id");
  const [pathRouter, setPathRouter] = useState({
    pathName: "",
    pathUrl: "/",
  });
  useEffect(() => {
    if (access) {
      setPathRouter({ pathName: t("ManageStudent"), pathUrl: "/manager" });
    } else {
      setPathRouter({ pathName: t("Log In"), pathUrl: "/auth" });
    }
  }, [access]);
  return (
    <div>
      <h1>404 PAGE NOT FOUND</h1>
      <div>
        <Link to={`${pathRouter.pathUrl}`}>{pathRouter.pathName}</Link>
        {`\n${t("Redirecting")}`}
      </div>
    </div>
  );
};

export default NotFound;
