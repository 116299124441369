import React, { createContext, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { getUsers, patchStudents } from "services/api";
import { AdminTable } from "components/Common/Table/AdminTable";
import {
  ADMIN_COLUMNS,
  ADMIN_EDIT_COLUMNS,
} from "components/Common/Table/columns";
import { useSetRecoilState } from "recoil";
import { isFilteringAtom } from "services/atom";
import { EditTable } from "components/Common/Table/EditTable";
import { t } from "i18next";
import ManagerForm from "components/Manager/ManagerForm";
import AdminHeader from "components/Common/Header/AdminHeader";
import { Loading } from "components/Common/Loading";

export const AdminContext = createContext(null);

const Admin = () => {
  // API에서 데이터를 가져오는 부분
  const { data: tableData, isLoading } = useQuery("korUsers", getUsers);
  // recoil 상태 관리 함수
  const setFiltering = useSetRecoilState(isFilteringAtom);

  // 상태값
  const [isUpdate, setUpdate] = useState(false);
  const [filters, setFilters] = useState({
    grade: "",
    academicStatus: "",
    class: "",
  });

  const [global, setGlobal] = useState("");
  const [changeItem, setChangeItem] = useState([]);

  const columns = useMemo(() => ADMIN_COLUMNS, []);
  const editColumns = useMemo(() => ADMIN_EDIT_COLUMNS, []);
  //EditTable state

  const { mutate } = useMutation(() => patchStudents(changeItem), {
    onSuccess: () => {
      // console.log("success");
      alert(t("Saved"));
      setChangeItem([]);
      setUpdate(false);
    },
  });

  //필터링에 사용되는 상태값을 업데이트
  const handleFilterChange = (e) => {
    if (!e || !e.target) return;
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };
  // 데이터 저장
  const handleSaving = async () => {
    setUpdate(true);
    if (isUpdate) {
      if (window.confirm(t("SaveChanges"))) {
        mutate();
      }
    }
  };
  //이전 화면으로 돌아감
  const handleBack = () => {
    if (window.confirm(t("BackNotSave"))) {
      setUpdate(false);
    }
  };
  //EditTable의 input 값이 변경될 때 호출
  const onChange = (origin) => (e) => {
    const { value, name } = e.target;
    const { _id } = origin;
    setChangeItem({
      ...changeItem,
      [_id]: {
        ...changeItem[_id],
        [name]: value,
      },
    });
  };

  useEffect(() => setFiltering(false), [setFiltering]);

  return (
    <ManagerForm
      type={"admin"}
      titleText={t("ManageStudent")}
      itemText={t("SearchStudent")}
      titleHeader={
        <AdminContext.Provider
          value={{
            isUpdate,
            global,
            setGlobal,
            filters,
            setFilters,
            handleFilterChange,
            handleBack,
            handleSaving,
          }}
        >
          <AdminHeader />
        </AdminContext.Provider>
      }
    >
      {isLoading && <Loading>Loading...</Loading>}
      {!isLoading && !isUpdate && (
        <AdminTable
          columns={columns}
          data={tableData}
          type={"admin"}
          isSort={true}
          setGrade={filters.grade}
          setStatus={filters.academicStatus}
          setClass={filters.class}
          globalFilter={global}
        />
      )}
      {isUpdate && (
        <EditTable
          columns={editColumns}
          data={tableData}
          onChange={onChange}
          type={"admin"}
        />
      )}
    </ManagerForm>
  );
};

export default Admin;
