import React, { useMemo, useState } from "react";
import ManagerForm from "components/Manager/ManagerForm";
import { useMutation, useQuery } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import { getRecordDetail, patchRecordDetail } from "services/api";
import styled from "styled-components";
import { EVAL_RECORDING_COLUMNS } from "components/Common/Table/columns";
import { useRecoilState } from "recoil";
import { setChangePointAtom } from "services/atom";
import { BasicTable } from "components/Common/Table/BasicTable";
import Header from "components/Common/Header";
import { t } from "i18next";
import { StyledBackIcon, StyledSaveIcon } from "assets/images/icon/Svg";
import { ItemButton } from "components/Common/Button/CustomButton";
import { Loading } from "components/Common/Loading";

const TitleBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1px;
  margin: 1px;
  span#title {
    font-size: 20px;
    font-weight: bold;
    align-items: flex-start;
  }
  label {
    color: gray;
  }
`;

const ButtonBoxContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const RecordingDetail = () => {
  const { id } = useParams();
  const navigation = useNavigate();

  if (id) {
  }

  // 시험 세부 정보 불러오기
  const columns = useMemo(() => EVAL_RECORDING_COLUMNS, []);
  const [itemPoints, setItemPoints] = useRecoilState(setChangePointAtom);
  const [recordData, setScoreData] = useState([]);
  const { data: examData, isLoading } = useQuery(
    "RecordDetail",
    () => getRecordDetail(id),
    {
      retry: 1,
      onError: () => {
        alert(t("ErrorMessage.InvalidURL"));
        navigation("../records");
      },
      onSuccess: (data) => {
        setScoreData(data.score_data_array);
      },
    }
  );

  const { mutate } = useMutation(
    () =>
      patchRecordDetail({
        user: id,
        change: itemPoints,
      }),
    {
      onSuccess: () => {
        setItemPoints([]);
        alert(t("Saved"));
      },
    }
  );

  const handleSaveChanges = () => {
    if (window.confirm(t("SaveChanges"))) {
      if (itemPoints.length) {
        mutate();
      } else {
        alert("변경할 데이터가 없습니다.");
      }
    }
  };

  return (
    <>
      <ManagerForm
        type={"eval"}
        titleText={t("ManageRecord")}
        itemText={t("SearchRecord")}
        titleHeader={
          !isLoading && (
            <Header width={260}>
              <TitleBoxContainer>
                <label>{`${t("SkillsItem")} / ${t(
                  "StudentID"
                )} / ${t("Name")}`}</label>
                <span id="title">{`${t(`Title.${examData.exam_class}`)} / ${examData?.studentid} / ${
                  examData?.name === "" ? t("Unregistered") : examData?.name
                }`}</span>
              </TitleBoxContainer>
              <ButtonBoxContainer>
                <ItemButton onClick={handleSaveChanges}>
                  <StyledSaveIcon /> <span> save</span>
                </ItemButton>
                <ItemButton
                  onClick={() => navigation("../records", { replace: true })}
                >
                  <StyledBackIcon />
                  <span>back</span>
                </ItemButton>
              </ButtonBoxContainer>
            </Header>
          )
        }
      >
        {isLoading && <Loading>Loading...</Loading>}
        <BasicTable
          columns={columns}
          data={recordData}
          title={examData?.exam_class}
          test={"testData"}
          type={"exam"}
        />
      </ManagerForm>
    </>
  );
};

export default RecordingDetail;
