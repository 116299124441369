import { useRecoilValue } from "recoil";
import { setFiltersDataAtom } from "services/atom";
import { USER_TABLE_TITLE_MAPPING } from "services/mapping";
import { StyledDownLoadIcon } from "assets/images/icon/Svg";
import { DownloadExcel } from "services/ExcelHandler";
import { renameKeys } from "services/renameKeys";
import styled from "styled-components";

const Item = styled.div`
  display: flex;
  width: 100%;
  padding: 5px 10px;
  justify-content: space-evenly;
`;

const ExportExcel = () => {
  const filteringJson = useRecoilValue(setFiltersDataAtom);
  const lng = JSON.parse(window.localStorage.getItem("lng"));

  //한글화 작업
  const mappingType = USER_TABLE_TITLE_MAPPING[lng.code];
  const convertDataKeys = renameKeys(mappingType, filteringJson);

  return (
    <Item onClick={() => DownloadExcel(convertDataKeys, "ManageStudent")}>
      <StyledDownLoadIcon />
      <span>DownLoad</span>
    </Item>
  );
};

export default ExportExcel;
