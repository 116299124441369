import React from "react";
import { TiUserOutline, TiLockClosed } from "react-icons/ti";
import { Banner } from "assets/images";
import LoginButton from "components/Common/Button/LoginButton";
import IconTextField from "components/Common/Input/IconTextField";
import {
  AuthBox,
  AuthContainer,
  BannerBox,
  Container,
  ErrorBlock,
  FooterBlock,
  FormBlock,
  GlobalStyle,
  HeaderBlock,
} from "./Styled";

const textMap = {
  login: "Sign in",
  register: "Sign up",
};

const AuthForm = ({
  type,
  handleLogin,
  handleChange,
  handleSubmit,
  errorMessage,
}) => {
  const text = textMap[type];

  return (
    <>
      <GlobalStyle />
      <AuthContainer>
        <Container>
          <BannerBox>
            <img src={Banner} alt="banner" />
          </BannerBox>
          <AuthBox>
            <HeaderBlock login={type === "login" ? true : false}>
              <span>{text}</span>
            </HeaderBlock>
            <FormBlock onKeyPress={handleSubmit} autoComplete="off">
              <IconTextField
                type="text"
                name="user"
                placeholder="User"
                onChange={handleChange}
              >
                <TiUserOutline />
              </IconTextField>
              <IconTextField
                type="password"
                name="password"
                placeholder="Password"
                autoComplete="off"
                onChange={handleChange}
              >
                <TiLockClosed />
              </IconTextField>
              {errorMessage !== "" && <ErrorBlock>{errorMessage}</ErrorBlock>}
            </FormBlock>
            <FooterBlock>
              <LoginButton
                color="#07193d"
                fontColor="#eee"
                onClick={handleLogin}
              />
            </FooterBlock>
          </AuthBox>
        </Container>
      </AuthContainer>
    </>
  );
};
export default AuthForm;
