import styled from "styled-components";
import { Box } from "@material-ui/core";

export const ImportExcelWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 5px 10px;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 4px;
  :hover {
    border: 1px solid #b9c0dd98;
  }
`;

export const ModalWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
  gap: 2rem;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  width: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const StyledLabel = styled.label`
  text-transform: uppercase;
`;
