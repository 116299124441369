import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
body{
    background-color: #F5F6FA;
}
`;
export const Page = styled.div`
  position: "relative";
  padding: 0 2.5rem 2.5rem 2.5rem;
  box-sizing: border-box;
  top: 0;
  height: 100%;
  /* margin-left: ${(props) => `${props.navWidth}px`}; */
  margin-left: 420px;
  margin-right: 20px;
  @media (max-width: 880px) {
    left: 0;
    width: 100%;
    margin: 0;
  }

  @media (max-width: 600px) {
    padding: 10px;
    font-size: 14px;
    left: 15%;
    width: 85%;
  }
`;

export const PageContants = styled.div`
  border-radius: 8px;
  border: 1px solid #ced2e0;
  padding: 10px;
  overflow: auto;
  overflow-x: hidden;
  background-color: #f5f6fa;
  height: 80vh;

  /* Hide scrollbar */
  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 10px;
  }
`;

export const PageHeader = styled.div`
  margin-bottom: 16px;
`;
export const Container = styled.div`
  height: 100%;
`;
