import React, { forwardRef } from "react";

const SelectCell = forwardRef(({ data, name, value, onChange }, ref) => {
  return (
    <select name={name} defaultValue={value} ref={ref} onChange={onChange}>
      {data.map((item, index) => (
        <option key={index}>{item}</option>
      ))}
    </select>
  );
});

export default SelectCell;
