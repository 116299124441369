import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  img {
    height: auto;
    width: auto;
    max-height: 24px;
  }

  @media (max-width: 880px) {
    margin-left: 10px;
    margin-right: 10px;
  }

  @media (min-width: 880px) {
    margin-bottom: 20px;
    margin-top: 4.5rem;
    img {
      max-height: none;
      max-width: none;
      height: 73px;
      width: 153px;
      margin-right: 0;
    }
  }
`;

const Logo = () => {
  const navigate = useNavigate();
  const home = () => {
    navigate("/manager/admin");
  };
  return (
    <Container>
      <img
        src={`${process.env.PUBLIC_URL}/Logo.png`}
        alt="logo"
        onClick={home}
        style={{ cursor: "pointer" }}
      />
    </Container>
  );
};

export default Logo;
